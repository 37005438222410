@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@font-face {
    font-family: "Sora",sans-serif;
    src: url("./fonts/Sora-Regular.ttf");
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Sora', sans-serif;
}

body,
button,
input,
select,
textarea {
    font-family: 'Sora', sans-serif;
}


.f-montserrat {
    font-family: 'Sora', sans-serif;
}

.f-minimal {
    font-family: 'Sora', serif;
}
