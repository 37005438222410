.valay-checkbox{
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-family:Sora, sans-serif;
}

.valay-checkbox >label{
    margin-left: 0.5rem;
    color:#9EA6A7;
}

.valay-checkbox >input{
    -webkit-appearance: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius:4px;

    color: transparent;
    border-color: #313633;
}

.valay-checkbox >input:checked:before{
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    background-color: #75BC34;
}