@media (max-width: 1600px) {


    .laptop\:va-top {
        vertical-align: top;
    }

    .laptop\:va-bottom {
        vertical-align: bottom;
    }

    .laptop\:va-center {
        vertical-align: center;
    }


    .laptop\:checkbox {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
    }

    .laptop\:checkbox:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDFMNiAxMkwxIDciIHN0cm9rZT0iIzFEMzc0NSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        background-size: 80%;
    }

    .laptop\:radio {
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        overflow: hidden;
        vertical-align: middle;
        appearance: none;
        background-color: white;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #1D3745;
        border-radius: 50%;
    }

    .laptop\:radio:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgZmlsbD0iIzFEMzc0NSIgc3Ryb2tlPSIjMUQzNzQ1Ii8+Cjwvc3ZnPgo=");
        background-size: 70%;
    }

    /*Squares*/
    .laptop\:icon {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-flex;
    }


    .laptop\:f-bernina {
        font-family: jaf-bernina-sans, sans-serif;
    }

    .laptop\:f-bernina-narrow {
        font-family: jaf-bernina-sans-narrow, sans-serif;
    }


    .laptop\:f-100 {
        font-weight: 100;
    }

    .laptop\:f-200 {
        font-weight: 200;
    }

    .laptop\:f-300 {
        font-weight: 300;
    }

    .laptop\:f-400 {
        font-weight: 400;
    }

    .laptop\:f-500 {
        font-weight: 500;
    }

    .laptop\:f-600 {
        font-weight: 600;
    }

    .laptop\:f-700 {
        font-weight: 700;
    }

    .laptop\:f-800 {
        font-weight: 800;
    }

    .laptop\:f-900 {
        font-weight: 900;
    }

    .laptop\:f-bold {
        font-weight: bold;
    }

    .laptop\:f-bolder {
        font-weight: bolder;
    }

    .laptop\:f-normal {
        font-weight: normal;
    }

    .laptop\:f-lighter {
        font-weight: lighter;
    }

    .laptop\:f-italic {
        font-style: italic;
    }

    .laptop\:f-underline {
        text-decoration: underline;
    }

    .laptop\:f-line-through {
        text-decoration: line-through;
    }

    .laptop\:f-uppercase {
        text-transform: uppercase;
    }

    .laptop\:f-1rem {
        font-size: 1rem;
    }

    .laptop\:f-medium {
        font-size: 1.2rem;
    }

    .laptop\:f-large {
        font-size: 1.5rem;
    }

    .laptop\:f-small {
        font-size: 0.8rem;
    }

    .laptop\:lh-80 {
        line-height: 80%;
    }

    .laptop\:lh-90 {
        line-height: 90%;
    }


    .laptop\:lh-100 {
        line-height: 100%;
    }

    .laptop\:lh-120 {
        line-height: 120%;
    }

    .laptop\:lh-150 {
        line-height: 150%;
    }

    .laptop\:lh-200 {
        line-height: 200%;
    }

    .laptop\:letter-1 {
        letter-spacing: 1px;
    }

    .laptop\:letter-2 {
        letter-spacing: 2px;
    }

    .laptop\:letter-3 {
        letter-spacing: 3px;
    }

    .laptop\:letter-4 {
        letter-spacing: 4px;
    }

    .laptop\:letter-5 {
        letter-spacing: 5px;
    }

    .laptop\:letter-6 {
        letter-spacing: 6px;
    }

    .laptop\:letter-1-n {
        letter-spacing: -1px;
    }

    .laptop\:letter-2-n {
        letter-spacing: -2px;
    }

    .laptop\:letter-3-n {
        letter-spacing: -3px;
    }

    .laptop\:letter-4-n {
        letter-spacing: -4px;
    }

    .laptop\:letter-5-n {
        letter-spacing: -5px;
    }

    .laptop\:letter-6-n {
        letter-spacing: -6px;
    }


    /*Titles*/
    .laptop\:h0 {
        font-size: 6rem;
    }

    .laptop\:h1, h1 {
        font-size: 4rem;
    }

    .laptop\:h2, h2 {
        font-size: 2.8rem;
    }

    .laptop\:h3, h3 {
        font-size: 2.5rem;
    }

    .laptop\:h4, h4 {
        font-size: 2rem;
    }

    .laptop\:h5, h5 {
        font-size: 1.6rem;
    }

    .laptop\:h6, h6 {
        font-size: 1.2rem;
    }

    /*Paragraph* */
    .laptop\:left {
        text-align: left;
    }

    .laptop\:right {
        text-align: right;
    }

    .laptop\:center {
        text-align: center;
    }

    /*textbox*/
    .laptop\:textbox a:not(.button) {
        text-decoration: underline;
    }

    .laptop\:textbox ul, .laptop\:textbox ol {
        padding-left: 2rem;
        margin-bottom: 1rem;
    }


    /*display*/
    .laptop\:flex {
        display: flex;
    }

    .laptop\:block, .laptop\:childs-block > * {
        display: block;
    }

    .laptop\:inline-flex {
        display: inline-flex;
    }

    .laptop\:grid {
        display: grid;
    }

    .laptop\:hidden {
        display: none;
    }

    /*position*/
    .laptop\:relative {
        position: relative;
    }

    .laptop\:absolute {
        position: absolute;
    }

    .laptop\:static {
        position: static;
    }

    .laptop\:fixed {
        position: fixed;
    }

    .laptop\:sticky {
        position: sticky;
    }

    .laptop\:edges {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .laptop\:left-auto {
        left: auto;
    }

    .laptop\:right-auto {
        right: auto;
    }

    .laptop\:top-auto {
        top: auto;
    }

    .laptop\:bottom-auto {
        bottom: auto;
    }

    .laptop\:top-1-n {
        top: -1rem;
    }

    .laptop\:bottom-1-n {
        bottom: -1rem;
    }

    .laptop\:left-1-n {
        left: -1rem;
    }

    .laptop\:right-1-n {
        right: -1rem;
    }

    .laptop\:top-0 {
        top: 0;
    }

    .laptop\:bottom-0 {
        bottom: 0;
    }

    .laptop\:left-0 {
        left: 0;
    }

    .laptop\:right-0 {
        right: 0;
    }

    .laptop\:top-quarter {
        top: 0.25rem
    }

    .laptop\:bottom-quarter {
        bottom: 0.25rem
    }

    .laptop\:left-quarter {
        left: 0.25rem
    }

    .laptop\:right-quarter {
        right: 0.25rem
    }


    .laptop\:top-half {
        top: 0.5rem
    }

    .laptop\:bottom-half {
        bottom: 0.5rem
    }

    .laptop\:left-half {
        left: 0.5rem
    }

    .laptop\:right-half {
        right: 0.5rem
    }


    .laptop\:top-1 {
        top: 1rem;
    }

    .laptop\:bottom-1 {
        bottom: 1rem;
    }

    .laptop\:left-1 {
        left: 1rem;
    }

    .laptop\:right-1 {
        right: 1rem;
    }

    .laptop\:top-2 {
        top: 2rem;
    }

    .laptop\:bottom-2 {
        bottom: 2rem;
    }

    .laptop\:left-2 {
        left: 2rem;
    }

    .laptop\:right-2 {
        right: 2rem;
    }

    .laptop\:top-3 {
        top: 3rem;
    }

    .laptop\:bottom-3 {
        bottom: 3rem;
    }

    .laptop\:left-3 {
        left: 3rem;
    }

    .laptop\:right-3 {
        right: 3rem;
    }

    .laptop\:top-4 {
        top: 4rem;
    }

    .laptop\:bottom-4 {
        bottom: 4rem;
    }

    .laptop\:left-4 {
        left: 4rem;
    }

    .laptop\:right-5 {
        right: 5rem;
    }

    .laptop\:top-5 {
        top: 5rem;
    }

    .laptop\:bottom-5 {
        bottom: 5rem;
    }

    .laptop\:left-5 {
        left: 5rem;
    }

    .laptop\:right-5 {
        right: 5rem;
    }

    .laptop\:top-6 {
        top: 6rem;
    }

    .laptop\:bottom-6 {
        bottom: 6rem;
    }

    .laptop\:left-6 {
        left: 6rem;
    }

    .laptop\:right-6 {
        right: 6rem;
    }

    .laptop\:top-8 {
        top: 8rem;
    }

    .laptop\:bottom-8 {
        bottom: 8rem;
    }

    .laptop\:left-8 {
        left: 8rem;
    }

    .laptop\:right-8 {
        right: 8rem;
    }

    .laptop\:top-10 {
        top: 10rem;
    }

    .laptop\:bottom-10 {
        bottom: 10rem;
    }

    .laptop\:left-10 {
        left: 10rem;
    }

    .laptop\:right-10 {
        right: 10rem;
    }

    .laptop\:left-6-n {
        left: -6rem;
    }

    .laptop\:right-6-n {
        right: -6rem;
    }

    .laptop\:left-8-n {
        left: -8rem;
    }

    .laptop\:right-8-n {
        right: -8rem;
    }

    .laptop\:left-10-n {
        left: -10rem;
    }

    .laptop\:right-10-n {
        right: -10rem;
    }

    .laptop\:left-12-n {
        left: -12rem;
    }

    .laptop\:right-12-n {
        right: -12rem;
    }

    .laptop\:left-14-n {
        left: -14rem;
    }

    .laptop\:right-14-n {
        right: -14rem;
    }


    .laptop\:left-15-n {
        left: -15rem;
    }

    .laptop\:right-15-n {
        right: -15rem;
    }

    .laptop\:left-16-n {
        left: -16rem;
    }

    .laptop\:right-16-n {
        right: -16rem;
    }

    .laptop\:left-20-n {
        left: -20rem;
    }

    .laptop\:right-20-n {
        right: -20rem;
    }


    .laptop\:top-2-n {
        top: -2rem;
    }

    .laptop\:bottom-2-n {
        bottom: -2rem;
    }


    .laptop\:top-3-n {
        top: -3rem;
    }

    .laptop\:bottom-3-n {
        bottom: -3rem;
    }


    .laptop\:top-4-n {
        top: -4rem;
    }

    .laptop\:bottom-4-n {
        bottom: -4rem;
    }


    .laptop\:top-6-n {
        top: -6rem;
    }

    .laptop\:bottom-6-n {
        bottom: -6rem;
    }

    .laptop\:top-10-n {
        top: -10rem;
    }

    .laptop\:bottom-10-n {
        bottom: -10rem;
    }

    .laptop\:top-15 {
        top: 15rem;
    }

    .laptop\:top-16 {
        top: 16rem;
    }


    /*Translate*/
    .laptop\:tx-100px {
        transform: translateX(100px);
    }

    .laptop\:tx-200px {
        transform: translateX(200px);
    }

    .laptop\:tx-300px {
        transform: translateX(300px);
    }

    .laptop\:tx-100px-n {
        transform: translateX(-100px);
    }

    .laptop\:tx-200px-n {
        transform: translateX(-200px);
    }

    .laptop\:tx-300px-n {
        transform: translateX(-200px);
    }

    /*Transition*/
    .laptop\:t-2 {
        transition: all 0.2s;
    }

    .laptop\:t-3 {
        transition: all 0.3s;
    }

    .laptop\:t-4 {
        transition: all 0.4s;
    }

    .laptop\:t-5 {
        transition: all 0.5s;
    }


    /*Zindex*/
    .laptop\:z-10 {
        z-index: 10;
    }

    .laptop\:z-20 {
        z-index: 20;
    }

    .laptop\:z-30 {
        z-index: 30;
    }

    .laptop\:z-40 {
        z-index: 40;
    }

    .laptop\:z-50 {
        z-index: 50;
    }

    .laptop\:z-60 {
        z-index: 60;
    }

    .laptop\:z-70 {
        z-index: 70;
    }

    .laptop\:z-80 {
        z-index: 80;
    }

    .laptop\:z-90 {
        z-index: 90;
    }

    .laptop\:z-100 {
        z-index: 100;
    }

    .laptop\:z-200 {
        z-index: 200;
    }

    .laptop\:z-300 {
        z-index: 300;
    }

    .laptop\:z-400 {
        z-index: 400;
    }

    .laptop\:z-500 {
        z-index: 500;
    }

    .laptop\:z-600 {
        z-index: 600;
    }

    .laptop\:z-700 {
        z-index: 700;
    }

    .laptop\:z-800 {
        z-index: 800;
    }

    .laptop\:z-900 {
        z-index: 900;
    }

    .laptop\:z-1000 {
        z-index: 1000;
    }


    /*layout flex*/
    .laptop\:align-center {
        align-items: center;
    }

    .laptop\:align-end {
        align-items: flex-end;
    }

    .laptop\:align-start {
        align-items: flex-start;
    }

    .laptop\:justify-center {
        justify-content: center;
    }

    .laptop\:justify-end {
        justify-content: flex-end;
    }

    .laptop\:justify-start {
        justify-content: flex-start;
    }

    .laptop\:grows {
        flex-grow: 1;
    }

    .laptop\:grows-0 {
        flex-grow: 0;
    }

    .laptop\:shrinks {
        flex-shrink: 1;
    }

    .laptop\:shrinks-0 {
        flex-shrink: 0;
    }

    .laptop\:column {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .laptop\:direction-column {
        flex-direction: column;
    }

    .laptop\:direction-row-reverse {
        flex-direction: row-reverse;
    }

    .laptop\:direction-column-reverse {
        flex-direction: column-reverse;
    }

    .laptop\:no-wrap {
        flex-wrap: nowrap;
    }

    .laptop\:wrap {
        flex-wrap: wrap;
    }

    .laptop\:order-1 {
        order: 1;
    }

    .laptop\:order-2 {
        order: 2;
    }

    .laptop\:order-3 {
        order: 3;
    }

    .laptop\:order-4 {
        order: 4;
    }

    .laptop\:order-5 {
        order: 5;
    }

    .laptop\:order-6 {
        order: 6;
    }

    .laptop\:order-7 {
        order: 7;
    }

    .laptop\:order-8 {
        order: 8;
    }

    .laptop\:order-9 {
        order: 9;
    }

    .laptop\:col2 {
        width: 50%;
    }

    .laptop\:col3 {
        width: 33.33%;
    }

    .laptop\:col4 {
        width: 25%;
    }

    .laptop\:col5 {
        width: 20%;
    }


    /*columns*/
    .laptop\:grid1 {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .laptop\:grid2 {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .laptop\:grid3 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .laptop\:grid4 {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .laptop\:grid5 {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .laptop\:grid6 {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .laptop\:grid7 {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .laptop\:grid8 {
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .laptop\:row-span2 {
        grid-row-start: span 2;
    }

    .laptop\:col-span2 {
        grid-column-start: span 2;
    }

    .laptop\:col-span3 {
        grid-column-start: span 3;
    }

    .laptop\:col-span4 {
        grid-column-start: span 4;
    }

    .laptop\:col-span5 {
        grid-column-start: span 5;
    }

    .laptop\:col-span6 {
        grid-column-start: span 5;
    }

    .laptop\:col-span7 {
        grid-column-start: span 5;
    }

    .laptop\:col-span8 {
        grid-column-start: span 5;
    }

    .laptop\:col-gap-quarter {
        grid-column-gap: 0.25rem;
    }

    .laptop\:col-gap-half {
        grid-column-gap: 0.5rem;
    }

    .laptop\:col-gap {
        grid-column-gap: 1rem;
    }

    .laptop\:col-gap-2 {
        grid-column-gap: 2rem;
    }

    .laptop\:col-gap-3 {
        grid-column-gap: 3rem;
    }

    .laptop\:col-gap-4 {
        grid-column-gap: 4rem;
    }

    .laptop\:col-gap-6 {
        grid-column-gap: 6rem;
    }

    .laptop\:col-gap-8 {
        grid-column-gap: 8rem;
    }

    .laptop\:row-gap-quarter {
        grid-row-gap: 0.25rem;
    }

    .laptop\:row-gap-half {
        grid-row-gap: 0.5rem;
    }

    .laptop\:row-gap {
        grid-row-gap: 1rem;
    }

    .laptop\:row-gap-2 {
        grid-row-gap: 2rem;
    }

    .laptop\:row-gap-3 {
        grid-row-gap: 3rem;
    }

    .laptop\:row-gap-4 {
        grid-row-gap: 4rem;
    }


    /*Sizes*/
    .laptop\:min-w-2400px {
        min-width: 2400px;
    }

    .laptop\:min-w-2000px {
        min-width: 2000px;
    }

    .laptop\:min-w-1800px {
        min-width: 1800px;
    }

    .laptop\:min-w-1600px {
        min-width: 1600px;
    }

    .laptop\:min-w-1400px {
        min-width: 1400px;
    }

    .laptop\:min-w-1360px {
        min-width: 1360px;
    }

    .laptop\:min-w-1200px {
        min-width: 1200px;
    }

    .laptop\:min-w-1056px {
        min-width: 1056px;
    }

    .laptop\:min-w-940px {
        min-width: 940px;
    }

    .laptop\:min-w-800px {
        min-width: 800px;
    }

    .laptop\:min-w-700px {
        min-width: 700px;
    }

    .laptop\:min-w-600px {
        min-width: 600px;
    }

    .laptop\:min-w-400px {
        min-width: 400px;
    }

    .laptop\:min-w-300px {
        min-width: 300px;
    }

    .laptop\:min-w-200px {
        min-width: 200px;
    }

    .laptop\:min-w-100px {
        min-width: 100px;
    }

    .laptop\:min-w-0 {
        min-width: 0;
    }

    .laptop\:min-w-100 {
        min-width: 100%;
    }

    .laptop\:min-w-90 {
        min-width: 90%;
    }

    .laptop\:min-w-80 {
        min-width: 80%;
    }

    .laptop\:min-w-70 {
        min-width: 70%;
    }

    .laptop\:min-w-60 {
        min-width: 60%;
    }

    .laptop\:min-w-50 {
        min-width: 50%;
    }


    .laptop\:max-w-2400px {
        max-width: 2400px;
    }

    .laptop\:max-w-2000px {
        max-width: 2000px;
    }

    .laptop\:max-w-1800px {
        max-width: 1800px;
    }

    .laptop\:max-w-1600px {
        max-width: 1600px;
    }

    .laptop\:max-w-1400px {
        max-width: 1400px;
    }

    .laptop\:max-w-1360px {
        max-width: 1360px;
    }

    .laptop\:max-w-1200px {
        max-width: 1200px;
    }

    .laptop\:max-w-1056px {
        max-width: 1056px;
    }

    .laptop\:max-w-940px {
        max-width: 940px;
    }

    .laptop\:max-w-800px {
        max-width: 800px;
    }

    .laptop\:max-w-700px {
        max-width: 700px;
    }

    .laptop\:max-w-600px {
        max-width: 600px;
    }

    .laptop\:max-w-500px {
        max-width: 500px;
    }

    .laptop\:max-w-400px {
        max-width: 400px;
    }

    .laptop\:max-w-350px {
        max-width: 350px;
    }

    .laptop\:max-w-300px {
        max-width: 300px;
    }

    .laptop\:max-w-200px {
        max-width: 200px;
    }

    .laptop\:max-w-100px {
        max-width: 100px;
    }

    .laptop\:max-w-100 {
        max-width: 100%;
    }

    .laptop\:max-w-90 {
        max-width: 90%;
    }

    .laptop\:max-w-80 {
        max-width: 80%;
    }

    .laptop\:max-w-70 {
        max-width: 70%;
    }

    .laptop\:max-w-60 {
        max-width: 60%;
    }

    .laptop\:max-w-50 {
        max-width: 50%;
    }

    /*Width*/
    .laptop\:w-0 {
        width: 0;
    }

    .laptop\:w-1rem {
        width: 1rem;
    }

    .laptop\:w-2rem {
        width: 2rem;
    }

    .laptop\:w-3rem {
        width: 3rem;
    }

    .laptop\:w-4rem {
        width: 4rem;
    }

    .laptop\:w-5rem {
        width: 5rem;
    }

    .laptop\:w-6rem {
        width: 6rem;
    }

    .laptop\:w-7rem {
        width: 7rem;
    }

    .laptop\:w-8rem {
        width: 8rem;
    }

    .laptop\:w-10rem {
        width: 10rem;
    }

    .laptop\:w-15rem {
        width: 15rem;
    }

    .laptop\:w-20rem {
        width: 20rem;
    }

    .laptop\:w-100 {
        width: 100%;
    }

    .laptop\:w-90 {
        width: 90%;
    }

    .laptop\:w-80 {
        width: 80%;
    }

    .laptop\:w-70 {
        width: 70%;
    }


    .laptop\:w-60 {
        width: 60%;
    }


    .laptop\:w-50 {
        width: 50%;
    }

    .laptop\:w-40 {
        width: 40%;
    }

    .laptop\:w-33 {
        width: 33.33%;
    }

    .laptop\:w-30 {
        width: 30%;
    }

    .laptop\:w-25 {
        width: 25%;
    }

    .laptop\:w-20 {
        width: 20%;
    }


    .laptop\:w-10 {
        width: 10%;
    }

    .laptop\:w-auto {
        width: auto;
    }


    .laptop\:w-10px {
        width: 10px;
    }

    .laptop\:w-20px {
        width: 20px;
    }

    .laptop\:w-30px {
        width: 30px;
    }

    .laptop\:w-40px {
        width: 40px;
    }

    .laptop\:w-50px {
        width: 50px;
    }

    .laptop\:w-60px {
        width: 60px;
    }

    .laptop\:w-70px {
        width: 70px;
    }

    .laptop\:w-80px {
        width: 80px;
    }

    .laptop\:w-90px {
        width: 90px;
    }

    .laptop\:w-100px {
        width: 100px;
    }

    .laptop\:w-150px {
        width: 150px;
    }

    .laptop\:w-200px {
        width: 200px;
    }

    .laptop\:w-250px {
        width: 250px;
    }

    .laptop\:w-300px {
        width: 300px;
    }

    .laptop\:w-350px {
        width: 350px;
    }

    .laptop\:w-400px {
        width: 400px;
    }

    .laptop\:w-500px {
        width: 500px;
    }

    .laptop\:w-600px {
        width: 600px;
    }

    .laptop\:w-800px {
        width: 800px;
    }

    .laptop\:w-1000px {
        width: 1000px;
    }


    /*height*/
    .laptop\:h-auto {
        height: auto;
    }

    .laptop\:h-halfrem {
        height: 0.5rem;
    }

    .laptop\:h-1rem {
        height: 1rem;
    }

    .laptop\:h-1halfrem {
        height: 1.5rem;
    }

    .laptop\:h-2rem {
        height: 2rem;
    }

    .laptop\:h-2halfrem {
        height: 2.5rem;
    }

    .laptop\:h-3rem {
        height: 3rem;
    }

    .laptop\:h-3halfrem {
        height: 3.5rem;
    }

    .laptop\:h-4rem {
        height: 4rem;
    }

    .laptop\:h-4halfrem {
        height: 4.5rem;
    }

    .laptop\:h-5rem {
        height: 5rem;
    }

    .laptop\:h-6rem {
        height: 6rem;
    }

    .laptop\:h-7rem {
        height: 7rem;
    }

    .laptop\:h-8rem {
        height: 8rem;
    }

    .laptop\:h-10rem {
        height: 10rem;
    }

    .laptop\:h-15rem {
        height: 15rem;
    }

    .laptop\:h-20rem {
        height: 20rem;
    }

    .laptop\:h-10vh {
        height: 10vh;
    }

    .laptop\:h-20vh {
        height: 20vh;
    }

    .laptop\:h-30vh {
        height: 30vh;
    }

    .laptop\:h-40vh {
        height: 40vh;
    }

    .laptop\:h-50vh {
        height: 50vh;
    }

    .laptop\:h-60vh {
        height: 60vh;
    }

    .laptop\:h-70vh {
        height: 70vh;
    }

    .laptop\:h-80vh {
        height: 80vh;
    }

    .laptop\:h-90vh {
        height: 90vh;
    }

    .laptop\:h-100vh {
        height: 100vh;
    }

    .laptop\:h-10 {
        height: 10%;
    }

    .laptop\:h-20 {
        height: 20%;
    }

    .laptop\:h-30 {
        height: 30%;
    }

    .laptop\:h-40 {
        height: 40%;
    }

    .laptop\:h-50 {
        height: 50%;
    }

    .laptop\:h-60 {
        height: 60%;
    }

    .laptop\:h-100 {
        height: 100%;
    }

    .laptop\:h-110 {
        height: 110%;
    }

    .laptop\:h-120 {
        height: 120%;
    }

    .laptop\:h-130 {
        height: 130%;
    }

    .laptop\:h-140 {
        height: 140%;
    }

    .laptop\:h-150 {
        height: 150%;
    }

    .laptop\:h-100px {
        height: 100px;
    }

    .laptop\:h-150px {
        height: 150px;
    }

    .laptop\:h-200px {
        height: 200px;
    }

    .laptop\:h-250px {
        height: 250px;
    }

    .laptop\:h-300px {
        height: 300px;
    }

    .laptop\:h-400px {
        height: 400px;
    }

    .laptop\:h-500px {
        height: 500px;
    }

    .laptop\:h-600px {
        height: 600px;
    }

    .laptop\:h-800px {
        height: 800px;
    }

    .laptop\:h-1000px {
        height: 1000px;
    }


    .laptop\:min-h-0 {
        min-height: 0;
    }

    .laptop\:min-h-100vh {
        min-height: 100vh;
    }

    .laptop\:min-h-90vh {
        min-height: 90vh;
    }

    .laptop\:min-h-80vh {
        min-height: 80vh;
    }

    .laptop\:min-h-70vh {
        min-height: 70vh;
    }

    .laptop\:min-h-60vh {
        min-height: 60vh;
    }

    .laptop\:min-h-50vh {
        min-height: 50vh;
    }

    .laptop\:min-h-40vh {
        min-height: 40vh;
    }

    .laptop\:min-h-30vh {
        min-height: 30vh;
    }

    .laptop\:min-h-20vh {
        min-height: 20vh;
    }

    .laptop\:min-h-10vh {
        min-height: 10vh;
    }


    .laptop\:min-h-100px {
        min-height: 100px;
    }

    .laptop\:min-h-200px {
        min-height: 200px;
    }

    .laptop\:min-h-300px {
        min-height: 300px;
    }

    .laptop\:min-h-400px {
        min-height: 400px;
    }

    .laptop\:min-h-500px {
        min-height: 500px;
    }

    .laptop\:min-h-600px {
        min-height: 600px;
    }

    .laptop\:min-h-700px {
        min-height: 700px;
    }

    .laptop\:min-h-800px {
        min-height: 800px;
    }


    .laptop\:max-h-0 {
        max-height: 0;
    }

    .laptop\:max-h-100vh {
        max-height: 100vh;
    }

    .laptop\:max-h-90vh {
        max-height: 90vh;
    }

    .laptop\:max-h-80vh {
        max-height: 80vh;
    }

    .laptop\:max-h-70vh {
        max-height: 70vh;
    }

    .laptop\:max-h-60vh {
        max-height: 60vh;
    }

    .laptop\:max-h-50vh {
        max-height: 50vh;
    }

    .laptop\:max-h-40vh {
        max-height: 40vh;
    }

    .laptop\:max-h-30vh {
        max-height: 30vh;
    }

    .laptop\:max-h-20vh {
        max-height: 20vh;
    }

    .laptop\:max-h-10vh {
        max-height: 10vh;
    }


    .laptop\:max-h-100px {
        max-height: 100px;
    }

    .laptop\:max-h-200px {
        max-height: 200px;
    }

    .laptop\:max-h-300px {
        min-height: 300px;
    }

    .laptop\:max-h-400px {
        min-height: 400px;
    }

    .laptop\:max-h-500px {
        max-height: 500px;
    }

    .laptop\:max-h-600px {
        max-height: 600px;
    }

    .laptop\:max-h-700px {
        max-height: 700px;
    }

    .laptop\:max-h-800px {
        max-height: 800px;
    }


    .laptop\:sq-1rem {
        width: 1rem;
        height: 1rem;
    }

    .laptop\:sq-1halfrem {
        width: 1.5rem;
        height: 1.5rem;
    }

    .laptop\:sq-2rem {
        width: 2rem;
        height: 2rem;
    }

    .laptop\:sq-2halfrem {
        width: 2.5rem;
        height: 2.5rem;
    }

    .laptop\:sq-3rem {
        width: 3rem;
        height: 3rem;
    }

    .laptop\:sq-3halfrem {
        width: 3.5rem;
        height: 3.5rem;
    }


    .laptop\:sq-4rem {
        width: 4rem;
        height: 4rem;
    }

    .laptop\:sq-4halfrem {
        width: 4.5rem;
        height: 4.5rem;
    }


    .laptop\:sq-5rem {
        width: 5rem;
        height: 5rem;
    }

    .laptop\:sq-6rem {
        width: 6rem;
        height: 6rem;
    }

    .laptop\:sq-7rem {
        width: 7rem;
        height: 7rem;
    }

    .laptop\:sq-8rem {
        width: 8rem;
        height: 8rem;
    }

    .laptop\:sq-10rem {
        width: 10rem;
        height: 10rem;
    }

    .laptop\:sq-15rem {
        width: 15rem;
        height: 15rem;
    }

    .laptop\:sq-20rem {
        width: 20rem;
        height: 20rem;
    }

    /*Overflows*/
    .laptop\:overflow-y-scroll {
        overflow-y: scroll;
    }

    .laptop\:overflow-y-auto {
        overflow-y: auto;
    }

    .laptop\:overflow-x-scroll {
        overflow-x: scroll;
    }

    .laptop\:overflow-x-hidden {
        overflow-x: hidden;
    }

    .laptop\:overflow-y-auto {
        overflow-x: auto;
    }

    .laptop\:overflow-hidden {
        overflow: hidden;
    }

    /*Margins*/
    .laptop\:m-0 {
        margin: 0;
    }

    .laptop\:m-auto {
        margin: auto;
    }

    .laptop\:mver-auto {
        margin-top: auto;
        margin-bottom: auto;
    }

    .laptop\:mhor-auto {
        margin-right: auto;
        margin-left: auto;
    }

    .laptop\:m-0 {
        margin: 0;
    }

    .laptop\:ml-0 {
        margin-left: 0;
    }

    .laptop\:mr-0 {
        margin-right: 0;
    }

    .laptop\:mt-0 {
        margin-top: 0;
    }

    .laptop\:mb-0 {
        margin-bottom: 0;
    }

    .laptop\:m {
        margin: 1rem;
    }

    .laptop\:ml {
        margin-left: 1rem;
    }

    .laptop\:mr {
        margin-right: 1rem;
    }

    .laptop\:mb, .laptop\:childs-mb > * {
        margin-bottom: 1rem;
    }

    .laptop\:mt {
        margin-top: 1rem;
    }

    .laptop\:mv {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .laptop\:mhor {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .laptop\:mver {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .laptop\:m-half {
        margin: 0.5rem;
    }

    .laptop\:ml-half {
        margin-left: 0.5rem;
    }

    .laptop\:mr-half {
        margin-right: 0.5rem;
    }

    .laptop\:mb-half, .laptop\:childs-mb-half > * {
        margin-bottom: 0.5rem;
    }

    .laptop\:mt-half {
        margin-top: 0.5rem;
    }

    .laptop\:mv-half {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .laptop\:mhor-half {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }


    .laptop\:m-quarter {
        margin: 0.25rem;
    }

    .laptop\:ml-quarter {
        margin-left: 0.25rem;
    }

    .laptop\:mr-quarter {
        margin-right: 0.25rem;
    }

    .laptop\:mb-quarter {
        margin-bottom: 0.25rem;
    }

    .laptop\:mt-quarter {
        margin-top: 0.25rem;
    }

    .laptop\:mver-quarter {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    .laptop\:mhor-quarter {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }


    .laptop\:m-2 {
        margin: 2rem;
    }

    .laptop\:ml-2 {
        margin-left: 2rem;
    }

    .laptop\:mr-2 {
        margin-right: 2rem;
    }

    .laptop\:mb-2 {
        margin-bottom: 2rem;
    }

    .laptop\:mt-2 {
        margin-top: 2rem;
    }

    .laptop\:mver-2 {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .laptop\:mhor-2 {
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .laptop\:m-3 {
        margin: 3rem;
    }

    .laptop\:ml-3 {
        margin-left: 3rem;
    }

    .laptop\:mr-3 {
        margin-right: 3rem;
    }

    .laptop\:mb-3 {
        margin-bottom: 3rem;
    }

    .laptop\:mb-3-n {
        margin-bottom: -3rem;
    }

    .laptop\:mt-3 {
        margin-top: 3rem;
    }

    .laptop\:mver-3 {
        margin-bottom: 3rem;
        margin-top: 3rem;
    }

    .laptop\:mhor-3 {
        margin-right: 3rem;
        margin-left: 3rem;
    }

    .laptop\:m-4 {
        margin: 4rem;
    }

    .laptop\:ml-4 {
        margin-left: 4rem;
    }

    .laptop\:ml-6 {
        margin-left: 6rem;
    }

    .laptop\:ml-8 {
        margin-left: 8rem;
    }

    .laptop\:ml-10 {
        margin-left: 10rem;
    }

    .laptop\:mr-4 {
        margin-right: 4rem;
    }

    .laptop\:mr-6 {
        margin-right: 6rem;
    }

    .laptop\:mr-8 {
        margin-right: 8rem;
    }

    .laptop\:mb-4 {
        margin-bottom: 4rem;
    }


    .laptop\:mb-6 {
        margin-bottom: 6rem;
    }

    .laptop\:mb-8 {
        margin-bottom: 8rem;
    }

    .laptop\:mb-10 {
        margin-bottom: 10rem;
    }

    .laptop\:mt-4 {
        margin-top: 4rem;
    }


    .laptop\:mt-6 {
        margin-top: 6rem;
    }


    .laptop\:mt-8 {
        margin-top: 8rem;
    }


    .laptop\:mt-10 {
        margin-top: 10rem;
    }

    .laptop\:mver-4 {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }

    .laptop\:mhor-4 {
        margin-right: 4rem;
        margin-left: 4rem;
    }

    /*Padding*/
    .laptop\:p-0 {
        padding: 0;
    }

    .laptop\:pl-0 {
        padding-left: 0;
    }

    .laptop\:pr-0 {
        padding-right: 0;
    }

    .laptop\:pb-0 {
        padding-bottom: 0;
    }

    .laptop\:pt-0 {
        padding-top: 0;
    }


    .laptop\:p {
        padding: 1rem;
    }

    .laptop\:pl {
        padding-left: 1rem;
    }

    .laptop\:pr {
        padding-right: 1rem;
    }

    .laptop\:pb {
        padding-bottom: 1rem;
    }

    .laptop\:pt {
        padding-top: 1rem;
    }

    .laptop\:pver-0 {
        padding-bottom: 0;
        padding-top: 0;
    }

    .laptop\:pver, .laptop\:childs-pver > * {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .laptop\:phor-0 {
        padding-right: 0;
        padding-left: 0;
    }

    .laptop\:phor {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .laptop\:p-half {
        padding: 0.5rem;
    }

    .laptop\:pl-half {
        padding-left: 0.5rem;
    }

    .laptop\:pr-half {
        padding-right: 0.5rem;
    }

    .laptop\:pb-half {
        padding-bottom: 0.5rem;
    }

    .laptop\:pt-half {
        padding-top: 0.5rem;
    }

    .laptop\:pver-half, .laptop\:childs-pver-half > * {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    .laptop\:phor-half {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }


    .laptop\:p-quarter {
        padding: 0.25rem;
    }

    .laptop\:pl-quarter {
        padding-left: 0.25rem;
    }

    .laptop\:pr-quarter {
        padding-right: 0.25rem;
    }

    .laptop\:pb-quarter {
        padding-bottom: 0.25rem;
    }

    .laptop\:pt-quarter {
        padding-top: 0.25rem;
    }

    .laptop\:pver-quarter, .laptop\:childs-pver-quarter > * {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
    }

    .laptop\:phor-quarter {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }


    .laptop\:p-2 {
        padding: 2rem;
    }

    .laptop\:pl-2 {
        padding-left: 2rem;
    }

    .laptop\:pr-2 {
        padding-right: 2rem;
    }

    .laptop\:pb-2 {
        padding-bottom: 2rem;
    }

    .laptop\:pt-2 {
        padding-top: 2rem;
    }

    .laptop\:pver-2 {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }

    .laptop\:phor-2 {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .laptop\:p-3 {
        padding: 3rem;
    }

    .laptop\:pl-3 {
        padding-left: 3rem;
    }

    .laptop\:pr-3 {
        padding-right: 3rem;
    }

    .laptop\:pb-3 {
        padding-bottom: 3rem;
    }

    .laptop\:pt-3 {
        padding-top: 3rem;
    }

    .laptop\:pver-3 {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }

    .laptop\:phor-3 {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .laptop\:p-4 {
        padding: 4rem;
    }

    .laptop\:pl-4 {
        padding-left: 4rem;
    }

    .laptop\:pr-4 {
        padding-right: 4rem;
    }

    .laptop\:pb-4 {
        padding-bottom: 4rem;
    }

    .laptop\:pt-4 {
        padding-top: 4rem;
    }

    .laptop\:pver-4 {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    .laptop\:phor-4 {
        padding-right: 4rem;
        padding-left: 4rem;
    }

    .laptop\:p-6 {
        padding: 6rem;
    }

    .laptop\:pl-6 {
        padding-left: 6rem;
    }

    .laptop\:pr-6 {
        padding-right: 6rem;
    }

    .laptop\:pb-6 {
        padding-bottom: 6rem;
    }

    .laptop\:pt-6 {
        padding-top: 6rem;
    }

    .laptop\:pver-6 {
        padding-bottom: 6rem;
        padding-top: 6rem;
    }

    .laptop\:phor-6 {
        padding-right: 6rem;
        padding-left: 6rem;
    }

    .laptop\:p-8 {
        padding: 8rem;
    }

    .laptop\:pl-8 {
        padding-left: 8rem;
    }

    .laptop\:pr-8 {
        padding-right: 8rem;
    }

    .laptop\:pb-8 {
        padding-bottom: 8rem;
    }

    .laptop\:pt-8 {
        padding-top: 8rem;
    }

    .laptop\:pver-8 {
        padding-bottom: 8rem;
        padding-top: 8rem;
    }

    .laptop\:phor-8 {
        padding-right: 8rem;
        padding-left: 8rem;
    }

    .laptop\:pb-100 {
        padding-bottom: 100%;
    }


    /*objects*/
    .laptop\:obj-cover {
        object-fit: cover;
    }

    .laptop\:obj-contain {
        object-fit: contain;
    }

    .laptop\:obj-top {
        object-position: top;
    }

    .laptop\:obj-bottom {
        object-position: bottom;
    }

    .laptop\:obj-left {
        object-position: left;
    }

    .laptop\:obj-top-left {
        object-position: left top;
    }

    .laptop\:obj-right {
        object-position: right;
    }

    .laptop\:bg-contain {
        background-size: contain;
    }

    .laptop\:bg-no-repeat {
        background-repeat: no-repeat;
    }

    .laptop\:bg-repeat {
        background-repeat: repeat;
    }

    .laptop\:bg-cover {
        background-size: cover;
    }

    .laptop\:bg-top {
        background-position: top;
    }

    .laptop\:bg-bottom {
        background-position: bottom;
    }

    .laptop\:bg-left {
        background-position: left;
    }

    .laptop\:bg-right {
        background-position: right;
    }

    .laptop\:bg-center {
        background-position: center;
    }


    /*Corners*/
    .laptop\:br-0 {
        border-radius: 0;
    }

    .laptop\:br-quarter {
        border-radius: 0.25rem;
    }

    .laptop\:br-half {
        border-radius: 0.5rem;
    }

    .laptop\:br-1half {
        border-radius: 1.5rem;
    }

    .laptop\:br {
        border-radius: 1rem;
    }

    .laptop\:br-2 {
        border-radius: 2rem;
    }

    .laptop\:br-2half {
        border-radius: 2.5rem;
    }

    .laptop\:br-3 {
        border-radius: 3rem;
    }

    .laptop\:br-4 {
        border-radius: 4rem;
    }

    .laptop\:br-8 {
        border-radius: 8rem;
    }

    .laptop\:br-50 {
        border-radius: 50%;
    }

    /*borders*/
    .laptop\:b-0 {
        border: 0;
    }

    .laptop\:b-top-0 {
        border-top: 0 solid;
    }

    .laptop\:b-right-0 {
        border-right: 0 solid;
    }

    .laptop\:b-bottom-0 {
        border-bottom: 0 solid;
    }

    .laptop\:b-left-0 {
        border-left: 0 solid;
    }

    .laptop\:b {
        border: 1px solid;
    }

    .laptop\:b-right {
        border-right: 1px solid;
    }

    .laptop\:b-bottom {
        border-bottom: 1px solid;
    }

    .laptop\:b-left {
        border-left: 1px solid;
    }

    .laptop\:b-top {
        border-top: 1px solid;
    }

    .laptop\:b-2 {
        border: 2px solid;
    }

    .laptop\:b-right-2 {
        border-right: 2px solid;
    }

    .laptop\:b-bottom-2 {
        border-bottom: 2px solid;
    }

    .laptop\:b-left-2 {
        border-left: 2px solid;
    }

    .laptop\:b-top-2 {
        border-top: 2px solid;
    }


    .laptop\:b-3 {
        border: 3px solid;
    }

    .laptop\:b-right-3 {
        border-right: 3px solid;
    }

    .laptop\:b-bottom-3 {
        border-bottom: 3px solid;
    }

    .laptop\:b-left-3 {
        border-left: 3px solid;
    }

    .laptop\:b-top-3 {
        border-top: 3px solid;
    }

    .laptop\:b-4 {
        border: 4px solid;
    }

    .laptop\:b-right-4 {
        border-right: 4px solid;
    }

    .laptop\:b-bottom-4 {
        border-bottom: 4px solid;
    }

    .laptop\:b-left-4 {
        border-left: 4px solid;
    }

    .laptop\:b-top-4 {
        border-top: 4px solid;
    }

    .laptop\:b-5 {
        border: 5px solid;
    }

    .laptop\:b-right-5 {
        border-right: 5px solid;
    }

    .laptop\:b-bottom-5 {
        border-bottom: 5px solid;
    }

    .laptop\:b-left-5 {
        border-left: 5px solid;
    }

    .laptop\:b-top-5 {
        border-top: 5px solid;
    }

    .laptop\:focus\:outline-0 {
        outline: 0;
    }

    /*Opacity*/
    .laptop\:op-0, .laptop\:hover\:op-0:hover {
        opacity: 0;
    }

    .laptop\:op-10, .laptop\:hover\:op-10:hover {
        opacity: .1;
    }

    .laptop\:op-20, .laptop\:hover\:op-20:hover {
        opacity: .20;
    }

    .laptop\:op-30, .laptop\:hover\:op-30:hover {
        opacity: .30;
    }

    .laptop\:op-40, .laptop\:hover\:op-40:hover {
        opacity: .40;
    }

    .laptop\:op-50, .laptop\:hover\:op-50:hover {
        opacity: .5;
    }

    .laptop\:op-60, .laptop\:hover\:op-60:hover {
        opacity: .60;
    }

    .laptop\:op-70, .laptop\:hover\:op-70:hover {
        opacity: .70;
    }

    .laptop\:op-80, .laptop\:hover\:op-80:hover {
        opacity: .80;
    }

    .laptop\:op-90, .laptop\:hover\:op-90:hover {
        opacity: .90;
    }

    .laptop\:op-100, .laptop\:hover\:op-100:hover {
        opacity: 1;
    }


    .laptop\:transition-3 {
        transition: all 0.3s;
    }

    .laptop\:transition-4 {
        transition: all 0.4s;
    }

    .laptop\:transition-5 {
        transition: all 0.5s;
    }

    .laptop\:transition-6 {
        transition: all 0.6s;
    }

    .laptop\:cursor-pointer {
        cursor: pointer;
    }

    .laptop\:list-none {
        list-style: none;
    }

    .laptop\:no-wrap {
        white-space: nowrap;
    }

    .laptop\:break-word {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }


    .laptop\:scale-1-1, .laptop\:hover\:scale-1-1:hover {
        transform: scale(1.1);
    }

    .laptop\:scale-1-2, .laptop\:hover\:scale-1-2:hover {
        transform: scale(1.2);
    }


    .laptop\:translate-y, .laptop\:hover\:translate-y:hover {
        transform: translateY(1rem);
    }

    .laptop\:translate-y-half, .laptop\:hover\:translate-y-half:hover {
        transform: translateY(0.5rem);
    }


    .laptop\:translate-y-n, .laptop\:hover\:translate-y-n:hover {
        transform: translateY(-1rem);
    }

    .laptop\:translate-y-half-n, .laptop\:hover\:translate-y-half-n:hover {
        transform: translateY(-0.5rem);
    }

}
